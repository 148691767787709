import { useLocation, useParams } from "react-router-dom";
import Items from "../ui/Items";
import NavBarFinderSticky from "../ui/NavBarFinderSticky";
import { useState } from "react";
import FeatureHighlights from "../ui/FeatureHighlights";

function Finder() {
  const { category } = useParams();
  const { state } = useLocation();
  const selectedItem = state?.selectedItem;
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <NavBarFinderSticky onSearch={handleSearch} category={category} />
      <div className="disclaimer-container">
        <FeatureHighlights iconName="Disclaimer" />
        <p className="disclaimer">
          Some of the links on this page are affiliate links, meaning, at no
          additional cost to you, we will earn a commission if you click through
          and make a purchase.
        </p>
      </div>
      <Items
        category={category}
        selectedItem={selectedItem}
        searchTerm={searchTerm}
      />
    </div>
  );
}

export default Finder;
