import { ReactComponent as Attach } from "../svg/attach_money_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Bolt } from "../svg/bolt_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Eco } from "../svg/eco_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Featured } from "../svg/featured_seasonal_and_gifts_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Savings } from "../svg/savings_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Search } from "../svg/search_FILL0_wght300_GRAD0_opsz40.svg";
import { ReactComponent as Shield } from "../svg/shield_FILL0_wght300_GRAD0_opsz40.svg";

import { ReactComponent as Categories } from "../svg/apps_FILL0_wght200_GRAD0_opsz48.svg";
import { ReactComponent as Filter } from "../svg/filter_list_FILL0_wght200_GRAD0_opsz48.svg";

import { ReactComponent as Instagram } from "../svg/instagram.svg";
import { ReactComponent as Facebook } from "../svg/facebook.svg";
import { ReactComponent as Pinterest } from "../svg/pinterest.svg";
import { ReactComponent as Xtwitter } from "../svg/x-twitter.svg";

import { ReactComponent as Disclaimer } from "../svg/info_24dp_FILL0_wght300_GRAD0_opsz24.svg";

export const Icons = {
  Attach,
  Bolt,
  Eco,
  Featured,
  Savings,
  Search,
  Shield,
  Categories,
  Filter,
  Instagram,
  Facebook,
  Pinterest,
  Xtwitter,
  Disclaimer,
};
