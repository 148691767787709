import React from "react";
import NavBar from "../ui/NavBar";

function Contact() {
  return (
    <>
      <NavBar />
      <div className="contact-page">
        <h1>Contact Us</h1>
        <p>Get in touch with us through our email or social media channels.</p>
      </div>
    </>
  );
}

export default Contact;
