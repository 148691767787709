import { useState } from "react";
import NewsletterModal from "../ui/Newsletter";
import NavBar from "../ui/NavBar";

function Blog() {
  const [showModal, setShowModal] = useState(false);

  const handleNewsletterClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <NavBar />
      <div className="blog-page">
        <p className="blog-text">
          Our blog is on its way! Stay tuned for updates and exciting content.
        </p>
        <button onClick={handleNewsletterClick} className="blog-button">
          Subscribe to our Newsletter
        </button>
        <NewsletterModal show={showModal} onClose={handleCloseModal} />
      </div>
    </>
  );
}

export default Blog;
