import { useEffect, useState } from "react";
import Footer from "../ui/Footer";
import MainSection from "../ui/MainSection";
import NavBar from "../ui/NavBar";
import SecondarySection from "../ui/SecondarySection";
import TertiarySection from "../ui/TertiarySection";
import supabase from "../supabaseClient";

function Home() {
  const [allItems, setAllItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchItems = async () => {
      const { data, error } = await supabase.from("products").select("*");
      if (error) {
        console.error("Error fetching items:", error);
      } else {
        setAllItems(data || []);
      }
    };
    fetchItems();
  }, []);

  const filteredItems = allItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <NavBar />
      <MainSection
        onSearch={setSearchTerm}
        searchResults={filteredItems}
        searchTerm={searchTerm}
      />
      <SecondarySection />
      <TertiarySection />
      <Footer />
    </div>
  );
}

export default Home;
