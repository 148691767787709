import NavBar from "../ui/NavBar";

function About() {
  return (
    <>
      <NavBar />
      <div className="about-page">
        <h1>About Us</h1>
        <p>Learn more about our mission and values.</p>
      </div>
    </>
  );
}

export default About;
