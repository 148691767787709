function SecondarySection() {
  return (
    <div className="secondary-section">
      <div className="secondary-description">
        <h2>Why Qualithic?</h2>
        <p>
          We carefully evaluate each item, selecting only the best from numerous
          brands and their various product lines to ensure you receive the most
          reliable and cost-effective version available. Not just that, we
          connect you with trusted testing sites for deeper insights and expert
          evaluations.
        </p>
        <br />
        <p>
          Explore our collection where every item is the perfect gift choice.
          Just pick a category, and you can't go wrong. Each product not only
          meets but often exceeds industry standards, ensuring quality and
          satisfaction for the long haul.
        </p>
        <br />
        <p>
          With Qualithic, every purchase is a step towards a lifetime of quality
          and satisfaction.
        </p>
      </div>
      <img
        className="secondary-image"
        src="/love-it.svg"
        alt="Illustration of a happy customer"
      />
    </div>
  );
}

export default SecondarySection;
