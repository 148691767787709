import FeatureHighlights from "./FeatureHighlights";

function TertiarySection() {
  return (
    <div>
      <div className="tertiary-section">
        <img
          className="tertiary-image"
          src="/favourite-item.svg"
          alt="Illustration of a happy customer"
        />
        <div className="tertiary-description">
          <h2>Value of Quality</h2>
          <p>
            Investing in higher quality products offers several long-term
            benefits that often outweigh the initial higher cost. When you
            choose quality, you're choosing products that last longer, perform
            better, and ultimately save you money and frustration.
          </p>
          <br />
          <div className="tertiary-features">
            <div>
              <FeatureHighlights iconName="Shield" text="Durability" />
              <span>
                Built to last with sturdy design and quality materials.
              </span>
            </div>
            <div>
              <FeatureHighlights iconName="Attach" text="Cost-Effectiveness" />
              <span>
                Higher initial cost, fewer replacements needed over time.
              </span>
            </div>
            <div>
              <FeatureHighlights iconName="Bolt" text="Performance" />
              <span>
                Superior performance ensures efficiency and effectiveness.
              </span>
            </div>
            <div>
              <FeatureHighlights iconName="Eco" text="Environment" />
              <span>
                Reduces waste and consumption, lowering environmental footprint.
              </span>
            </div>
          </div>
          <p>
            By opting for superior products, you not only enhance your daily
            routines but also contribute to a more sustainable future. It's
            about making smart choices that benefit you today and preserve
            tomorrow. Embrace quality with us and experience the difference in
            every use.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TertiarySection;
