import { useState } from "react";
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";

function NavBar() {
  const [showModal, setShowModal] = useState(false);

  const handleNewsletterClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <nav className="nav-container">
      <Link to="/" className="logo-link">
        <img src="/QualithicLogo.png" alt="Qualithic" className="logo" />
      </Link>
      <div className="nav-items">
        <Link to="/blog">
          <button className="blog-btn">Blog</button>
        </Link>
        <button onClick={handleNewsletterClick}>Newsletter</button>
      </div>
      <Newsletter show={showModal} onClose={handleCloseModal} />
    </nav>
  );
}

export default NavBar;
