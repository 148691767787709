import React from "react";
import NavBar from "../ui/NavBar";

function Resources() {
  return (
    <>
      <NavBar />
      <div className="resources-page">
        <h1>Resources</h1>
        <p>
          <strong>
            Social media icons:
            <a
              href="https://www.iconfinder.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Iconfinder
            </a>
          </strong>
        </p>
        <p>
          <strong>
            Illustrations:
            <a
              href="https://undraw.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Undraw
            </a>
          </strong>
        </p>
      </div>
    </>
  );
}

export default Resources;
