import { Link } from "react-router-dom";
import { Icons } from "./icons"; // Adjust the path as necessary

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/resources">Resources</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/about">About Us</Link>
        <Link to="/contact">Contact Us</Link>
      </div>
      <p className="footer-text">© 2024 Qualithic. All rights reserved.</p>
      <div className="social-media">
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Instagram />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Facebook />
        </a>
        <a
          href="https://www.pinterest.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Pinterest />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Xtwitter />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
