import { Icons } from "./icons";
import { useRef } from "react";

function Search({ className, onSearch, placeholder }) {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    onSearch(e.target.value);
  };

  const handleFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        const offset = rect.top + window.scrollY - 20;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }, 200);
  };

  return (
    <div className={className}>
      <input
        ref={inputRef}
        type="text"
        className="search-input"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <Icons.Search />
    </div>
  );
}

export default Search;
