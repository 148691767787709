import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import CategoryButton from "./CategoryButton";
import FeatureHighlights from "./FeatureHighlights";
import Search from "./Search";

function MainSection({ onSearch, searchResults = [], searchTerm }) {
  const navigate = useNavigate();

  const handlePreviewClick = (item) => {
    const firstCategory = item.category[0];
    navigate(`/finder/${firstCategory}`, { state: { selectedItem: item } });
  };

  return (
    <div className="main-section">
      <h1>Quality Item Finder</h1>
      <h2>discover products that you'll keep for a lifetime</h2>
      <h3>Start your search</h3>
      <Search className="search" onSearch={onSearch} placeholder="Search..." />
      {searchTerm && searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((item) => (
            <div
              key={item.id}
              className="search-preview"
              onClick={() => handlePreviewClick(item)}
            >
              <img
                src={item.image}
                alt="Thumbnail of "
                className="search-thumbnail"
              />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      )}
      <h3>...or browse through our categories</h3>
      <div className="main-grid">
        <Link to="/finder/essentials">
          <CategoryButton label="Essentials < $100" />
        </Link>
        <Link to="/finder/home-kitchen">
          <CategoryButton label="Home & Kitchen" />
        </Link>
        <Link to="/finder/beauty-health">
          <CategoryButton label="Beauty & Health" />
        </Link>
        <Link to="/finder/fashion">
          <CategoryButton label="Fashion" />
        </Link>
        <Link to="/finder/outdoor-sports">
          <CategoryButton label="Outdoor & Sports" />
        </Link>
        <Link to="/finder/electronics">
          <CategoryButton label="Electronics" />
        </Link>
        <Link to="/finder/travel">
          <CategoryButton label="Travel" />
        </Link>
        <Link to="/finder/tools-diy">
          <CategoryButton label="Tools & DIY" />
        </Link>
      </div>
      <div className="feature-highlights">
        <FeatureHighlights
          iconName="Search"
          text="Discover unmatched quality items"
        />
        <FeatureHighlights
          iconName="Featured"
          text="Find the perfect lifelong gift"
        />
        <FeatureHighlights
          iconName="Savings"
          text="Exceptional choices for every budget"
        />
      </div>
    </div>
  );
}

export default MainSection;
