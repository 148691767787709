import { Icons } from "./icons";

function FeatureHighlights({ iconName, text }) {
  const Icon = Icons[iconName];

  return (
    <div className="feature-item">
      {Icon ? <Icon /> : null}
      {text}
    </div>
  );
}

export default FeatureHighlights;
