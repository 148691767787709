import React, { useState } from "react";
import supabase from "../supabaseClient";

function NewsletterModal({ show, onClose }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (name.trim() === "") {
      setNameError("Please enter your name.");
      valid = false;
    } else {
      setNameError("");
    }

    if (valid) {
      const { error } = await supabase
        .from("newsletter_subscribers")
        .insert([{ name, email }]);

      if (error) {
        console.error("Error inserting data:", error);
      } else {
        setSubmissionSuccess(true);
        setEmail("");
        setName("");

        setTimeout(() => {
          setSubmissionSuccess(false);
          onClose();
        }, 2000);
      }
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="newsletter-overlay" onClick={onClose}>
      <div className="newsletter-content" onClick={(e) => e.stopPropagation()}>
        <h2>Subscribe to our Newsletter</h2>
        <p>
          Right now, we're dedicated to curating the best quality items for you.
          Our upcoming newsletter will keep you informed about the latest
          additions and exclusive insights. Subscribe today so you don't miss
          out!
        </p>
        {submissionSuccess ? (
          <p className="thanks-message">Thank you for subscribing! :)</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />

              {nameError && <p className="newsletter-error">{nameError}</p>}
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />

              {emailError && <p className="error">{emailError}</p>}
            </div>
            <button type="submit" className="newsletter-button">
              Subscribe
            </button>
          </form>
        )}
        <img
          className="newsletter-image"
          src="/sign-up.svg"
          alt="Illustration of signing up"
        />
        <button className="newsletter-cancel-button" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
    </div>
  );
}

export default NewsletterModal;
