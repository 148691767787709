import { useState, useEffect } from "react";
import { Icons } from "./icons";
import { Link } from "react-router-dom";
import CategoriesModal from "./CategoriesModal";
import Search from "./Search";
import { formatCategoryName } from "../utils/formatCategoryLetters";

function NavBarFinder({ onSearch, category }) {
  const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCategoriesClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const placeholderText = `Search within ${formatCategoryName(category)}`;

  return (
    <nav className={`nav-finder-container ${isSticky ? "solid" : ""}`}>
      <Link to="/" className="logo-link">
        <img src="/QualithicLogo.png" alt="Qualithic" className="logo-finder" />
      </Link>
      <div className="nav-finder-menu">
        <Search
          className="searchFinder"
          onSearch={onSearch}
          placeholder={placeholderText}
        />
        <div className="nav-finder-menu-items" onClick={handleCategoriesClick}>
          <span>
            <Icons.Categories />
          </span>
          <span>Categories</span>
        </div>
      </div>
      <CategoriesModal show={isModalOpen} onClose={handleCloseModal} />
    </nav>
  );
}

export default NavBarFinder;
