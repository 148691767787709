import React from "react";
import { Link } from "react-router-dom";

const CategoriesModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const categories = [
    { name: "Home & Kitchen", path: "/finder/home-kitchen" },
    { name: "Personal Care", path: "/finder/personal-care" },
    { name: "Fashion", path: "/finder/fashion" },
    { name: "Outdoor & Sports", path: "/finder/outdoor-sports" },
    { name: "Electronics", path: "/finder/electronics" },
    { name: "Travel", path: "/finder/travel" },
    { name: "Beauty", path: "/finder/beauty" },
    { name: "Tools & DIY", path: "/finder/tools-diy" },
  ];

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="categories-list">
          {categories.map((category) => (
            <Link
              key={category.name}
              to={category.path}
              onClick={onClose}
              className="category-link"
            >
              <button className="category-button">{category.name}</button>
            </Link>
          ))}
        </div>
        <button className="cancel-button" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
    </div>
  );
};

export default CategoriesModal;
