import { useState } from "react";
import { ReactComponent as ReadMoreIcon } from "../svg/read_more_24dp_FILL0_wght200_GRAD0_opsz24.svg";

function Item({ item }) {
  const [showFullText, setShowFullText] = useState(false);

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const truncateText = (text, wordLimit) => {
    return text.split(" ").slice(0, wordLimit).join(" ") + "... ";
  };

  const renderPrimaryButton = (button) => {
    const [name, url] = Object.entries(button)[0];
    return (
      <button
        className="items-button"
        onClick={() => window.open(url, "_blank")}
      >
        {name} &rarr;
      </button>
    );
  };

  return (
    <div className="items-container">
      <img className="items-image" src={item.image} alt={item.title} />
      <div className="text-container">
        <h3 className="items-title">{item.title}</h3>
        <p className="items-description">
          {showFullText ? item.description : truncateText(item.description, 50)}
          <span className="read-more-button" onClick={toggleReadMore}>
            {showFullText ? "Read Less" : "Read More"}
          </span>
        </p>
      </div>
      <div className="link-container">
        {item.links &&
          Object.entries(item.links).map(([key, value], index) => (
            <a
              key={index}
              href={value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {key}
              <ReadMoreIcon className="link-icon" />
            </a>
          ))}
      </div>
      {item.button && renderPrimaryButton(item.button)}
    </div>
  );
}

export default Item;
