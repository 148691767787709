import { useEffect, useRef, useState } from "react";

import Item from "./Item";
import supabase from "../supabaseClient";
import Spinner from "./Spinner";

function Items({ category, selectedItem, searchTerm }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemRefs = useRef({});

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);

      const categoryArray = JSON.stringify([category]);

      const { data, error } = await supabase
        .from("products")
        .select("*")
        // Single category item
        // .eq("category", category);
        .contains("category", categoryArray);

      if (error) {
        console.log("Error fetching items:", error);
      } else if (data.length === 0) {
        console.log("No items found for category:", category);
      }
      setItems(data || []);
      setLoading(false);
    };
    fetchItems();
  }, [category]);

  useEffect(() => {
    if (selectedItem && itemRefs.current[selectedItem.id]) {
      const navHeight = document.querySelector(
        ".nav-finder-container"
      ).offsetHeight;
      const additionalOffset = 20;

      const scrollToPosition =
        itemRefs.current[selectedItem.id].offsetTop -
        navHeight -
        additionalOffset;
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });

      itemRefs.current[selectedItem.id].classList.add("highlight");
    }
    // Single category item
    // }, [selectedItem, items]);
  }, [selectedItem, items]);

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <Spinner />;
  if (!filteredItems.length) return <p>No items found.</p>;

  return (
    <div className="content-area">
      {filteredItems.map((item) => (
        <div
          key={item.id}
          id={item.id}
          ref={(el) => (itemRefs.current[item.id] = el)}
          className={`item ${
            selectedItem && selectedItem.id === item.id ? "highlight" : ""
          }`}
        >
          <Item item={item} />
        </div>
      ))}
    </div>
  );
}

export default Items;
